export const data = [
  {
    id: 1,
    image: "images/eventoCastellonMarzo23.jpeg",
    date: "03/30/2023",
    city: "Castellon",
    visible: true
  },
  {
    id: 2,
    image: "images/eventoBarcelonaAbril23.jpeg",
    date: "04/04/2023",
    city: "Barcelona (Convento San Agustin)",
    visible: true
  },
  {
    id: 3,
    image: "images/2ClaustroDeLamerce2023.jpg",
    date: "11/11/2023",
    city: "Girona (Claustro de la Merce)",
    visible: true
  },
  {
    id: 4,
    image: "images/1BibliotecaBonPastor2024.jpg",
    date: "05/05/2024",
    city: "Barcelona (Biblioteca de Bon Pastor)",
    visible: true
  },
  {
    id: 5,
    image: "images/2Las3Chimeneas2024.jpg",
    date: "10/17/2024",
    city: "Barcelona (Las 3 chimeneas de San Adrian Del Besos)",
    visible: true
  },
  {
    id: 6,
    image: "images/1CentroCulturalBesos2024.jpg",
    date: "11/08/2024",
    city: "Barcelona (Centro Cultural festival 'Infusión Flamenca')",
    visible: true
  },
  {
    id: 7,
    image: "images/eventoMadridDiciembre2023.jpg",
    date: "12/09/2023",
    city: "Madrid",
    visible: true
  },
];
